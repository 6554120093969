<template>
  <div>
    <a-card :border="false">
    <div class="query">
      <a-form-model :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
        <a-row :gutter="4">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="开票主体">
              <a-select
                placeholder="请选择开票主体"
                v-model="dealerIdSearch"
                @change="changeOpenInvoiceInfo"
                allowClear
              >
                <a-select-option :value="item.dealerId" v-for="(item, index) in dealerSearchDataList" :key="index">
                  {{ item.dealerName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="订单号">
              <a-input v-model.trim="queryParam.orderCode" placeholder="订单号" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="发货单号">
              <a-input v-model.trim="queryParam.shipCode" placeholder="发货单号" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="收货人">
              <a-input v-model.trim="queryParam.consignee" placeholder="收货人" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="所在省市区">
              <PCD
                placeholder="所在省市区"
                :province.sync="queryParam.provinceName"
                :provinceId.sync="queryParam.provinceId"
                :city.sync="queryParam.cityName"
                :cityId.sync="queryParam.cityId"
                :district.sync="queryParam.area"
                :districtId.sync="queryParam.areaId"
              ></PCD>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="详细地址">
              <a-input v-model.trim="queryParam.address" placeholder="详细地址" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="发货时间">
              <DateRange :startTime.sync="queryParam.startTime" :endTime.sync="queryParam.endTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div>
      <!-- <a-button @click="onPrint()" type="primary" v-if="isPrint">打印</a-button> -->
      <a-button @click="toHandler(null, 'info')" type="primary" v-if="openInvoice && selectedRowKeys.length > 0">开票</a-button>
      <a-button type="primary" v-if="selectedRowKeys.length > 0 && isEditInvoice" @click="toHandlerEdit" icon="form">修改发票号</a-button>
      <a-upload
        name="file"
        :action="uploadUrl"
        :headers="headers"
        :showUploadList="false"
        @change="uploadChange"
      >
        <a-button v-if="isInvoiceImport" icon="upload" type="default">导入</a-button>
      </a-upload>
      <!-- <a-button @click="toHandler(null, 'ship')" type="primary" v-if="isLogistics">完善物流信息</a-button> -->
<!--      <downLoad
        method="get"
        api="/api/order/system/orderShipInfo/exportOrderShipInfo"
        v-if="isExport"
        :params="queryParam"
        name="发货单列表.xlsx"
        >批量导出发货单</downLoad
      >
      <downLoad
        v-if="isExport && selectedRowKeys.length > 0 && isOrderDetail"
        method="get"
        api="/api/order/system/orderShipInfo/exportOrderShipInfoDetail"
        :params="getRowId"
        name="发货单明细.xlsx"
        >导出订单明细</downLoad
      >-->
    </div>
    <div class="table">
      <a-table
        :loading="tableLoading"
        :scroll="{ x: 1700 }"
        :data-source="tableData"
        type="checkbox"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        bordered
        :customRow="changeTableRow"
        :pagination="page"
        @change="
          (p) => {
            page = p
            toSearch(2)
          }
        "
      >
        <a-table-column title="发货单号" data-index="shipCode" :width="210" :ellipsis="true" align="left" fixed="left">
          <template slot-scope="text">
            <a-tooltip
              ><template slot="title"> {{ text }} </template> {{ text }}
            </a-tooltip>
          </template>
        </a-table-column>
        <a-table-column title="订单号" data-index="orderCode" :width="210"> </a-table-column>
        <a-table-column title="发票号" data-index="invoiceNo" :width="210">
          <template slot-scope="text">{{ text != null ? text : '/' }}</template>
        </a-table-column>
        <a-table-column title="实际支付价" data-index="actualPrice" :width="210"> </a-table-column>
<!--        <a-table-column title="件数" data-index="caseNumber" :width="80"> </a-table-column>-->
        <a-table-column title="总重量" data-index="grossWeight" :width="80"> </a-table-column>
        <a-table-column title="运费" data-index="freight" :width="80"> </a-table-column>
        <a-table-column title="收货人" data-index="consignee" :width="210" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="收货人电话" data-index="phone" :width="120" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="地址" data-index="street" :width="450" :ellipsis="true" align="left">
          <template slot-scope="text, record"
            >{{ record.provinceName }}{{ record.cityName }}{{ record.areaName }}{{ record.address }}</template
          >
        </a-table-column>
        <a-table-column title="生成方式" data-index="sourceType" :width="80" :ellipsis="true" align="left">
          <template slot-scope="text">{{ text | sourceType }}</template>
        </a-table-column>
        <a-table-column title="发货时间" data-index="createTime" :width="210" :ellipsis="true" align="left">
        </a-table-column>
      </a-table>
    </div>
    <a-modal
      :maskClosable="false"
      :title="title"
      style="top: 8px"
      :width="800"
      v-model="visible"
      :confirmLoading="isLoading"
      @cancel="toCancel"
      :footer="null"
    >
      <a-table size="small" :data-source="tableData2" bordered rowKey="id" :pagination="false">
        <a-table-column
          title="序号"
          data-index="index"
          :width="60"
          align="center"
          :customRender="
            (text, record, index) => {
              return index + 1
            }
          "
        >
        </a-table-column>
        <a-table-column title="发货单号" data-index="shipCode" :width="140" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="物流信息" data-index="remark" :width="200" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="操作时间" data-index="time" :width="140" :ellipsis="true" align="left"> </a-table-column>
        <a-table-column title="操作" data-index="action" :width="60" :ellipsis="true" align="center">
          <template slot-scope="text, record">
            <a class="a_link" @click="toHandler(record, 'delShip')">删除</a>
          </template>
        </a-table-column>
      </a-table>
      <div style="margin-top: 14px">
        <a-form-model ref="form" :model="form" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
          <a-row :gutter="4">
            <a-col :md="24">
              <a-form-model-item
                label="物流信息"
                prop="remark"
                :rules="[{ required: true, message: '请输入物流信息', trigger: 'blur' }]"
              >
                <a-textarea placeholder="请输入物流信息" v-model.trim="form.remark"></a-textarea>
              </a-form-model-item>
              <a-form-model-item label="附件">
                <QiniuUpload type="image" :value.sync="form.imgUrl" :size="3"></QiniuUpload>
                <div class="upload-hint-text">支持扩展名：.jpg .png</div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div style="text-align: right; margin-top: 14px">
            <a-button type="primary" @click="toSubmit">确认新增物流</a-button>
          </div>
        </a-form-model>
      </div>
    </a-modal>
    <a-modal title="打印预览" width="80%" v-model="putVisible" :confirmLoading="putloading" :footer="null">
      <div id="printwrap">
        <template>
          <!--            <div class="printDiv" style="width: 100%; page-break-before: auto; page-break-after: always">-->
          <div class="printDiv" style="width: 100%; page-break-before: auto">
            <div
              class="printTab01"
              style="display: flex; justify-content: space-between; padding-bottom: 5px; border-bottom: 1px solid #000"
            >
              <div class="div1">
                <!-- <div class="logo-msg" style="display: flex;align-items: center;height: 60px;">
                    <div class="logo"><img :src="bsLogoImg" width="150" style="max-height:60px;" alt=""></div>
                  </div> -->
              </div>
              <div class="div2" style="display: flex">
                <!-- <div class="text" style="margin: 0 3pt;width: 120px;text-align: center;">
                    <div><img :src="bsWxImg" width="60" height="60" alt=""></div>
                    <div class="mt5" style="font-size: 12px;line-height: 20px;">微信公众号</div>
                  </div> -->
              </div>
              <div class="div3">
                <div style="height: 60px" id="qrcode"></div>
                <!-- <div class="mt5" style="text-align: center;font-weight: 600; margin-top: 3px; font-size: 14px;">{{printOrderInfoList.orderCode}}</div> -->
              </div>
            </div>
            <div class="printTab02">
              <table
                style="width: 100%; table-layout: fixed"
                align="center"
                class="protable"
                border="0"
                cellpadding="0"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th
                      style="
                        white-space: nowrap;
                        padding: 5px 10px;
                        border-bottom: 1px solid #000;
                        font-weight: bold;
                        font-size: 13px;
                        width: 150px;
                      "
                    >
                      商品名称
                    </th>
                    <th
                      style="
                        white-space: nowrap;
                        padding: 5px 10px;
                        border-bottom: 1px solid #000;
                        font-weight: bold;
                        font-size: 13px;
                        width: 110px;
                      "
                    >
                      规格
                    </th>
                    <th
                      style="
                        white-space: nowrap;
                        padding: 5px 10px;
                        border-bottom: 1px solid #000;
                        font-weight: bold;
                        font-size: 13px;
                      "
                    >
                      单位
                    </th>
                    <th
                      style="
                        white-space: nowrap;
                        padding: 5px 10px;
                        border-bottom: 1px solid #000;
                        font-weight: bold;
                        font-size: 13px;
                      "
                    >
                      购买量
                    </th>
                    <th
                      style="
                        white-space: nowrap;
                        padding: 5px 10px;
                        border-bottom: 1px solid #000;
                        font-weight: bold;
                        font-size: 13px;
                      "
                    >
                      商品单价
                    </th>
                    <th
                      style="
                        white-space: nowrap;
                        padding: 5px 10px;
                        border-bottom: 1px solid #000;
                        font-weight: bold;
                        font-size: 13px;
                      "
                    >
                      促销价
                    </th>
                    <th
                      style="
                        white-space: nowrap;
                        padding: 5px 10px;
                        border-bottom: 1px solid #000;
                        font-weight: bold;
                        font-size: 13px;
                      "
                    >
                      商品金额
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in printOrderInfoList.infoDetailsAndGoods" :key="index">
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px; width: 150px">
                      {{ data.goodsName }}
                    </td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px; width: 110px">
                      {{ data.specStr }}
                    </td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px">{{ data.proUnit }}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px">{{ data.demandNum }}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px">
                      &yen;{{ data.discountBeforePrice }}
                    </td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px">&yen;{{ data.price }}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px">
                      &yen;{{ data.demandNum * data.price }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="printTab03">
              <table style="width: 100%" align="center" class="protable" border="0" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th style="padding: 5px 10px; border-top: 1px solid #000; font-weight: bold; font-size: 13px">
                      订单金额
                    </th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000; font-weight: bold; font-size: 13px">
                      优惠金额
                    </th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000; font-weight: bold; font-size: 13px">
                      运费金额
                    </th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000; font-weight: bold; font-size: 13px">
                      应收金额
                    </th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000; font-weight: bold; font-size: 13px">
                      实收金额
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px">
                      &yen;{{ printOrderInfoList.totalAmount }}
                    </td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px">
                      &yen;{{ printOrderInfoList.totalAmount - printOrderInfoList.payAmount }}
                    </td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px">&yen;--</td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px">&yen;--</td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px">
                      &yen;{{ printOrderInfoList.payAmount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="printTab04">
              <table
                style="width: 100%; table-layout: fixed"
                align="center"
                class="protable"
                border="0"
                cellpadding="0"
                cellspacing="0"
              >
                <tbody>
                  <tr>
                    <td style="padding: 2px 10px; font-size: 12px; width: 170px">
                      收货人：{{ printOrderInfoList.customerName }}
                    </td>
                    <td style="padding: 2px 10px; font-size: 12px; width: 170px">
                      联系方式：{{ printOrderInfoList.mobile }}
                    </td>
                    <td style="padding: 2px 10px; font-size: 12px">支付情况：暂无</td>
                  </tr>
                  <tr>
                    <td style="padding: 2px 10px 4px 10px; border-bottom: 1px solid #000; font-size: 12px" colspan="2">
                      收货地址：{{ printOrderInfoList.customerArea }}{{ printOrderInfoList.address }}
                    </td>
                    <td style="padding: 2px 10px 4px 10px; border-bottom: 1px solid #000; font-size: 12px">
                      订单备注：{{ printOrderInfoList.orderRemark }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="printTab06">
              <table style="width: 100%" align="center" class="protable" border="0" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr>
                    <td style="padding: 2px 10px 4px 10px; font-size: 12px">快递公司名称：</td>
                    <td style="padding: 2px 10px 4px 10px; font-size: 12px">快递单号：</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
      <div class="" align="center">
        <a-button type="primary" v-print="'#printwrap'" align="center">立即打印</a-button>
      </div>
    </a-modal>
    <OrderInfoModal ref="orderInfoModal" @change="toSearch(1)"></OrderInfoModal>
    <a-modal v-model="visibleEdit" title="修改发票号" @ok="handleOk"  @cancel="handleCance" :maskClosable="false" width="40%" :confirm-loading="confirmLoading">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="发票号">
          <a-input v-model="invoiceCode" placeholder="请输入发票号"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    </a-card>
  </div>
</template>
<script src='../../../static/js/lib/jquery.print.js'></script>
<script>
const qs = require('qs')
import QRCode from 'qrcodejs2'
import {baseURL} from '@/utils/request'
import OrderInfoModal from '@/views/order_ship/components/OpenInvoice.vue'
import { checkPermission } from '@/utils/permissions'

export default {
  data() {
    return {
      putloading: false,
      putVisible: false,
      queryParam: {},
      value: '',
      resetQueryParam: null,
      tableData: [],
      tableLoading: false, //表格loading
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      visible: false,
      visibleEdit: false,
      title: '物流信息',
      form: {},
      isLoading: false,
      tableData2: [],
      row: null,
      invoiceCode:'',
      shipCode:'',
      confirmLoading:false,
      printOrderInfoList: {},
      dealerSearchDataList: [],
      dealerIdSearch: undefined,
      isPrint: checkPermission('ordersend:shippingOrder:printing'),
      isOrderDetail: checkPermission('ordersend:shippingOrder:exportOrderDetailed'),
      isExport: checkPermission('invoiceApply:export'),
      isLogistics: checkPermission('ordersend:shippingOrder:logistics'),
      openInvoice: checkPermission('invoiceApply:Invoicing'),
      isInvoiceImport: checkPermission("order:invoice:import"),
      isEditInvoice:checkPermission('invoiceApply:editInvoice'),
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/order/invoice/orderShipInfo/excelReader',
      //上传文件请求头
      headers: {
        'authorization-distributor': this.$store.getters.token,
      },
    }
  },
  components: {
    OrderInfoModal,
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'checkbox', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
          console.log(_this.selectedRowKeys)
        },
      }
    },
    getRowId() {
      return { id: this.selectedRowKeys[0] }
    },
  },
  methods: {
    // 审核弹框
    toHandlerEdit() {
      let _this=this;
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      _this.visibleEdit = true;
    },
    handleOk(){
      this.confirmLoading = true
      let params = {
        invoiceCode: this.invoiceCode,
        shipCode: this.selectedRows[0].shipCode
      }
      this.axios.post('/api/order/invoice/orderShipInfo/updateInvoiceCode', params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visibleEdit = false
          this.toSearch(0)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    handleCance(){
      this.confirmLoading = false
      this.visibleEdit = false
      this.invoiceCode = ''
      this.shipCode = ''
    },
    // uploadChange(info) {
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList)
    //   }
    // },
    uploadChange (info) {
        this.tableLoading = true
        setTimeout(()=>{
        if(info.file.response){
        let hint = info.file.response
        if(hint.code === 200) {
          this.tableLoading = false
          this.$message.success(hint.message)
          this.toSearch(0)
        }else if(hint.code === 500) {
          this.tableLoading = false
          this.$message.error(hint.message)
        }
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList)
        }
        }
        })

      },
    onChangeShipDate(date, dateString) {
      console.log(date, dateString)
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    changeOpenInvoiceInfo(value){
      console.log(value);
      this.dealerIdSearch = value;
      this.queryParam.dealerId = value;
      this.toSearch(0);
    },
    toSearch(type) {
      this.tableLoading = true
      if (type == 1) {
        this.axios
        .get('/api/order/invoice/orderShipInfo/searchOpenDealerList')
        .then((res) => {
          this.dealerSearchDataList = res.body
          this.queryParam.dealerId = this.dealerSearchDataList[0].dealerId
          this.dealerIdSearch = this.dealerSearchDataList[0].dealerId
        })
        .catch((err) => {})
        this.resetQueryParam
          ? (this.queryParam = Object.assign({}, this.resetQueryParam))
          : (this.resetQueryParam = Object.assign({}, this.queryParam))
      }
      this.queryParam.pageNumber = type == 2 ? this.page.current : (this.page.current = 1)
      this.queryParam.pageSize = this.page.pageSize
      const postData = Object.assign({}, this.queryParam, this.page)
      console.log(postData)
      console.log(this.queryParam.dealerId)
      this.axios
        .get(`/api/order/invoice/orderShipInfo/list?${qs.stringify(postData)}`)
        .then((res) => {
          this.tableData = res.body.records
          this.page.total = res.body.total
          this.tableLoading = false
        })
        .catch((err) => {
          this.tableLoading = false
        }).finally(() => (this.tableLoading = false))
    },
    toCancel() {
      this.$refs.form && this.$refs.form.resetFields()
      this.visible = false
    },
    toSearch2() {
      this.tableLoading = true
      let postData = {
        shipCode: this.selectedRows[0].shipCode,
        orderCode: this.selectedRows[0].orderCode,
        logisticsCode: this.selectedRows[0].deliveryNum,
      }
      this.axios.get(`/api/order/system/orderShipLogistics/list?${qs.stringify(postData)}`).then((res) => {
        this.tableData2 = res.body.logisticsList
        this.form.imgUrl = res.body.imgUrl
      }).finally(() => (this.tableLoading = false))
    },
    // 打印
    onPrint(item) {
      if (this.selectedRows.length === 0) {
        this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      this.axios
        .get(`/api/order/system/orderInfo/selectByCode?orderCode=${this.selectedRows[0].orderCode}`)
        .then((res) => {
          this.putloading = false
          this.putVisible = true
          this.printOrderInfoList = res.body
          this.$nextTick(function () {
            document.getElementById('qrcode').innerHTML = ''
            let qrcode = new QRCode('qrcode', {
              width: 60,
              height: 60,
              text: res.body.orderCode,
              colorDark: '#109dff',
              colorLight: '#d9d9d9',
            })
          })
        })
        .catch((error) => {
          this.putloading = false
        })
    },
    /**
     * 增、查、删（存在type）
     */
    toHandler(row, name) {
      if (this.selectedRows.length <= 0) {
        return this.$message.warning('请选择一条数据')
      }
      switch (name) {
        case 'info':
          this.$refs.orderInfoModal.showInfo(
            this.selectedRows[0].orderCode,
            this.selectedRows[0].shipCode,
            'shipOrder',
            this.selectedRowKeys
          )
          // this.$refs.orderInfoModal.showInfo(row.orderCode,row.shipCode)
          break
        case 'ship':
          this.visible = true
          this.form = {}
          this.row = row
          this.toSearch2()
          break
        case 'info':
          this.visible = true
          this.form = {}
          this.row = row
          this.toSearch2()
          break
        case 'delShip':
          this.$confirm({
            title: '确定删除该条记录',
            onOk: () => {
              this.axios
                .post(`/api/order/system/orderShipLogistics/del/${row.id}`)
                .then((res) => {
                  this.$message.success(res.message)
                  this.form = {}
                  this.toSearch2(0)
                })
                .catch((err) => {})
            },
          })
          break
      }
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    /**
     * 提交
     */
    toSubmit() {
      let tmp = this.form
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let postData = Object.assign({}, tmp, {
            shipCode: this.selectedRows[0].shipCode,
          })
          this.isLoading = true
          this.axios
            .post(`/api/order/system/orderShipLogistics/add?${qs.stringify(postData)}`)
            .then((res) => {
              this.$message.success(res.message)
              this.toSearch2()
              this.form = {}
              this.visible = false
            })
            .catch((err) => {})
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
  created() {
    this.axios
        .get('/api/order/invoice/orderShipInfo/searchOpenDealerList')
        .then((res) => {
          this.dealerSearchDataList = res.body
          this.queryParam.dealerId = this.dealerSearchDataList[0].dealerId
          this.dealerIdSearch = this.dealerSearchDataList[0].dealerId
          this.toSearch(1)
        })
        .catch((err) => {})
  },
}
</script>


<style lang="less" scoped>
</style>
